import { IDropdownOption } from '@fluentui/react';

export const stateOptions: IDropdownOption[] = [
	{key: 'NSW', text: 'New South Wales'}, 
	{key: 'VIC', text: 'Victoria'}, 
	{key: 'QLD', text: 'Queensland'}, 
	{key: 'WA',  text: 'Western Australia'}, 
	{key: 'SA',  text: 'South Australia'}, 
	{key: 'TAS', text: 'Tasmania'}, 
	{key: 'ACT', text: 'Australian Capital Territory'}, 
	{key: 'NT',  text: 'Northern Territory'}
];