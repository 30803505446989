import { Dropdown } from '@fluentui/react';
import { EditorProps } from './types';

export type DropdownEditorProps = {
  options?: any;
  textKey?: any;
  valueKey?: any;
} & EditorProps;

const DropdownEditor = (props: DropdownEditorProps) => {
  const items = [...props?.options].map((o) => ({
    ...o,
    key: o[props?.valueKey],
    text: o[props?.textKey],
  }));

  const handleChange = (_: any, item: any) => {
    props.onRowChange(
      { ...props.row, [props.column.key]: item[props.valueKey] },
      true
    );
  };

  return (
    <Dropdown
      selectedKey={props.row[props.column.key] || undefined}
      onChange={handleChange}
      options={items}
    />
  );
};

export default DropdownEditor;
