import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Label,
  IconButton,
  SelectionMode,
  IDetailsFooterProps,
  DetailsRow,
  MessageBar,
  MessageBarType,
  Stack,
  StackItem,
  IColumn,
  DetailsListLayoutMode,
  TextField,
} from '@fluentui/react';
import { Spacer } from './Spacer';
import { getJobRoleColumns, HOURS_PER_DAY } from '../enums/jobRoleBudgetFields';
import DetailsListWithContextMenu from './DetailsListWithContextMenu';
import './ClientJobBudget.css';
import { useOpportunitiesLists } from '../hooks/useOpportunities';
import {
  useJobRoles,
  useJobSingle,
  useUpdateJob,
  useUpdateJobRoles,
} from '../hooks/useJobs';
import { IOpportunity } from '../types/IOpportunity';
import { financialPermissions } from '../enums/permissions';
import { useCurrentUser } from '../providers/CurrentUserProvider';
import { IJob } from 'types/IJob';
import { IJobRole, JobRoleColumnTotals } from 'types/IJobRole';
import { useInvoices } from 'hooks/useInvoices';
import { invoicesPageFields } from 'enums/invoicesPageFields';

const getJobRoleFieldTotal = (field: string, items?: IJobRole[]) => {
  return (items ?? []).reduce((sum, cur: any) => {
    const value = parseFloat(cur[field] + '');
    if (!isNaN(value)) {
      return sum + value;
    }
    return sum;
  }, 0);
};

const Footer = (detailsFooterProps?: IDetailsFooterProps): JSX.Element => {
  return (
    <DetailsRow
      {...detailsFooterProps}
      columns={detailsFooterProps?.columns}
      item={{}}
      itemIndex={-1}
      groupNestingDepth={detailsFooterProps?.groupNestingDepth}
      selectionMode={SelectionMode.none}
      selection={detailsFooterProps?.selection}
    />
  );
};

type JobRolesBudgetsProps = {
  job: IJob;
  jobRoles: IJobRole[];
  canViewMoneyValues: boolean;
  canEditBudgetTotal: boolean;
};

const JobRolesBudgets: React.FC<JobRolesBudgetsProps> = (
  props: JobRolesBudgetsProps
) => {
  const [jobData, setJobData] = useState<IJob>(props.job);
  const [jobRolesData, setJobRoles] = useState<IJobRole[]>(props.jobRoles);
  const [columnTotals, setColumnTotals] = useState<JobRoleColumnTotals>({
    totalBudgetDays: 0,
    totalBookedDays: 0,
    totalVarianceDays: 0,
    totalBudgetAmount: 0,
    totalBookedAmount: 0,
    totalVarianceAmount: 0,
  });
  const {
    update: updateJob,
    isLoading: isJobUpdating,
    error: updateJobError,
  } = useUpdateJob();
  const {
    update: updateJobRole,
    isLoading: isJobRoleUpdating,
    error: updateJobRoleError,
  } = useUpdateJobRoles({ jobId: props?.job?.id?.toString() });

  const refreshColumnTotals = (items: IJobRole[]) => {
    const totalBudgetDays = getJobRoleFieldTotal('hours_budget', items);
    const totalBookedDays = getJobRoleFieldTotal('hours_booked', items);
    const totalBudgetAmount = items.reduce((value, role) => {
      const total = ((role.hours_budget ?? 0) / HOURS_PER_DAY) * role.sell_rate;
      value += total;
      return value;
    }, 0);
    const totalBookedAmount = items.reduce((value, role) => {
      const total = ((role.hours_booked ?? 0) / HOURS_PER_DAY) * role.sell_rate;
      value += total;
      return value;
    }, 0);
    setColumnTotals({
      totalBudgetDays,
      totalBookedDays,
      totalVarianceDays: totalBudgetDays - totalBookedDays,
      totalBudgetAmount,
      totalBookedAmount,
      totalVarianceAmount: totalBudgetAmount - totalBookedAmount,
    });
  };

  /* calculate variance by budget_total - roles total(days x sell rate) */
  const budgetVariance = useMemo(() => {
    const budgetTotal =
      jobData?.budget_total == null ||
      Number.isNaN(jobData?.budget_total) ||
      jobData?.budget_total < 0
        ? 0
        : jobData.budget_total;
    const jobRolesTotalBudget =
      jobRolesData?.reduce((prev, curr) => {
        const days = (curr.hours_budget ?? 0) / HOURS_PER_DAY;
        prev += days * (curr.sell_rate ?? 0);
        return prev;
      }, 0) ?? 0;

    return budgetTotal - jobRolesTotalBudget;
  }, [jobData?.budget_total, jobRolesData]);

  const generalError = useMemo(
    () =>
      updateJobError || updateJobRoleError
        ? 'An error occurred while saving the budget.'
        : '',
    [updateJobError, updateJobRoleError]
  );

  const isSubmitting = useMemo(
    () => isJobUpdating || isJobRoleUpdating,
    [isJobRoleUpdating, isJobUpdating]
  );

  const onSave = async () => {
    if (!jobData || !jobRolesData) return;
    updateJob({
      clientId: jobData.client_id.toString(),
      payload: {
        budget_total: jobData.budget_total,
        id: jobData.id,
        name: jobData.name,
        client_id: jobData.client_id,
        status: jobData.status,
        business_unit: jobData.business_unit,
        project_lead_id: jobData.project_lead_id,
        reference: jobData.reference,
      } as IJob,
    });

    updateJobRole(
      jobRolesData.map((i) => ({
        hours_budget: i.hours_budget,
        id: i.id,
        name: i.name,
        active: i.active,
        sell_rate: i.sell_rate,
        job_id: i.job_id,
      }))
    );
  };

  useEffect(() => {
    setJobData(props.job);
  }, [props.job]);

  useEffect(() => {
    setJobRoles(props.jobRoles);
  }, [props.jobRoles]);

  const handleBudgetTotalChange = (value: string) => {
    if (!jobData) return;
    const amount = parseFloat(value);
    setJobData({
      ...jobData,
      budget_total: Number.isNaN(amount) ? 0 : amount,
    });
  };

  // computes job role's hours total (multiplied by HOURS_PER_DAY)
  const handleHoursTotalChange = (item: IJobRole, newValue: any) => {
    if (!jobRolesData?.length) return;
    const newHoursTotal = isNaN(newValue) || !newValue ? 0 : parseFloat(newValue);
    setJobRoles((prev) => {
      return prev.map((role) => {
        if (role.id === item.id) {
          return {
            ...role,
            hours_budget: newHoursTotal * HOURS_PER_DAY,
          };
        }
        return role;
      });
    });
  };

  useEffect(() => {
    if (!props?.jobRoles?.length) return;
    refreshColumnTotals(props.jobRoles);
  }, [props?.jobRoles]);

  useEffect(() => {
    if (!jobRolesData?.length) return;
    refreshColumnTotals(jobRolesData);
  }, [jobRolesData]);

  return (
    <div className="da-details-list-container da-client-job-budget-item">
      {generalError && (
        <div style={{ padding: '1em' }}>
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            {generalError}
          </MessageBar>
        </div>
      )}
      <div className="da-details-list-header da-job-budget-header">
        <h2>Budget</h2>
        <Spacer />
        {props.canViewMoneyValues && (
          <>
            <Label>Budget Variance</Label>
            <span style={{ color: budgetVariance < 0 ? 'var(--warning)' : '' }}>
              {budgetVariance}
            </span>
            <Label>Budget Total</Label>
            {props.canEditBudgetTotal ? (
              <TextField
                defaultValue={props?.job?.budget_total?.toString()}
                onChange={(_: any, value: any) =>
                  handleBudgetTotalChange(value)
                }
                disabled={isSubmitting}
                type="number"
              />
            ) : (
              <span>{props?.job?.budget_total}</span>
            )}
          </>
        )}
        <IconButton
          iconProps={{ iconName: 'Save' }}
          ariaLabel="Save"
          disabled={isSubmitting}
          onClick={onSave}
        />
      </div>

      <DetailsListWithContextMenu
        items={jobRolesData ?? []}
        columns={getJobRoleColumns(
          columnTotals,
          handleHoursTotalChange,
          props.canViewMoneyValues,
          isSubmitting
        )}
        selectionMode={SelectionMode.none}
        onRenderDetailsFooter={Footer}
      />
    </div>
  );
};

const OpportunitiesGrid: React.FC<{
  opportunities: IOpportunity[];
  canViewMoneyValues: boolean;
}> = (props: {
  opportunities: IOpportunity[];
  canViewMoneyValues: boolean;
}) => {
  const opportunitiesFields: IColumn[] = [
    {
      key: 'name',
      fieldName: 'name',
      name: 'Name',
      minWidth: 100,
      maxWidth: 300,
    },
    {
      key: 'close_date',
      fieldName: 'close_date',
      name: 'Close Date',
      minWidth: 120,
      maxWidth: 120,
    },
  ];
  if (props.canViewMoneyValues) {
    opportunitiesFields.push({
      key: 'value',
      fieldName: 'value_currency',
      name: 'Value',
      minWidth: 80,
      maxWidth: 80,
    });
  }

  return (
    <div className="da-details-list-container">
      <div className="da-details-list-header">
        <h2 className="da-details-list-title">Opportunities</h2>
      </div>
      <DetailsListWithContextMenu
        columns={opportunitiesFields}
        items={props?.opportunities}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.fixedColumns}
      />
    </div>
  );
};

export const ClientJobBudget: React.FC = () => {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const { jobId } = useParams<any>();
  const {
    opportunities = [],
    isLoading: opportunitiesLoading,
    error: opportunitiesError,
  } = useOpportunitiesLists();
  const { job, isLoading: jobLoading, error: jobError } = useJobSingle(jobId);
  const {
    jobRoles,
    isLoading: jobRolesLoading,
    error: jobRolesError,
  } = useJobRoles(jobId);
  const {
    invoices,
    isLoading: invoicesLoading,
    error: invoicesError,
  } = useInvoices();

  const isLoading = useMemo(
    () =>
      opportunitiesLoading || jobLoading || jobRolesLoading || invoicesLoading,
    [jobLoading, jobRolesLoading, opportunitiesLoading, invoicesLoading]
  );
  const error = useMemo(
    () => opportunitiesError || jobError || jobRolesError || invoicesError,
    [jobError, jobRolesError, opportunitiesError, invoicesError]
  );

  const filteredOpportunities = useMemo(
    () => opportunities.filter((o) => o.job_id?.toString() === jobId),
    [opportunities, jobId]
  );

  const filteredInvoices = useMemo(
    () => invoices.filter((o) => o.job_id?.toString() === jobId),
    [invoices, jobId]
  );

  const canViewMoneyValues = useMemo(() => {
    if (!currentUser) return false;
    const userPermissions = currentUser?.access_rules?.map((r) => r.role) ?? [];
    return (
      userPermissions.includes(financialPermissions.editAll) ||
      (userPermissions.includes(financialPermissions.editMe) &&
        currentUser.id === job?.project_lead_id)
    );
  }, [currentUser, job]);

  const canEditBudgetTotal = useMemo(() => {
    const userPermissions = currentUser?.access_rules?.map((r) => r.role) ?? [];
    return userPermissions.includes(financialPermissions.editAll);
  }, [currentUser]);

  const invoicesColumns = useMemo(() => {
    const columns = invoicesPageFields.filter((f) => f.key !== 'job_id');
    const colTotal = columns.find((c) => c.key === 'amount_total') as IColumn;
    if (colTotal) {
      const totalSum = filteredInvoices?.reduce((value, item) => {
        return value + item.amount_total;
      }, 0);
      colTotal.onRender = (item?: any, index?: number) => {
        if (index === -1) return totalSum;
        return item.amount_total;
      };
    }
    const colDue = columns.find((c) => c.key === 'amount_due') as IColumn;
    if (colDue) {
      const totalSum = filteredInvoices?.reduce((value, item) => {
        return value + item.amount_due;
      }, 0);
      colDue.onRender = (item?: any, index?: number) => {
        if (index === -1) return totalSum;
        return item.amount_due;
      };
    }
    return columns;
  }, [filteredInvoices]);

  return (
    <div className="da-job-budget">
      {error && (
        <div style={{ padding: '1em' }}>
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            {error!.toString()}
          </MessageBar>
        </div>
      )}
      {!isLoading && (
        <Stack tokens={{ childrenGap: 24 }}>
          <StackItem>
            <JobRolesBudgets
              job={job}
              jobRoles={jobRoles}
              canViewMoneyValues={canViewMoneyValues}
              canEditBudgetTotal={canEditBudgetTotal}
            />
          </StackItem>
          <StackItem>
            {!!filteredOpportunities?.length ? (
              <OpportunitiesGrid
                opportunities={filteredOpportunities}
                canViewMoneyValues={canViewMoneyValues}
              />
            ) : (
              <h2 className="da-details-list-title">No linked opportunities</h2>
            )}
          </StackItem>
          <StackItem>
            {!!filteredInvoices?.length ? (
              <div className="da-details-list-container">
                <div className="da-details-list-header">
                  <h2 className="da-details-list-title">Invoices</h2>
                </div>
                <DetailsListWithContextMenu
                  items={filteredInvoices}
                  columns={invoicesColumns}
                  selectionMode={SelectionMode.none}
                  onRenderDetailsFooter={Footer}
                  onActiveItemChanged={(item) =>
                    item && history.push(`/invoices/${item.id}`)
                  }
                />
              </div>
            ) : (
              <h2 className="da-details-list-title">No linked invoices</h2>
            )}
          </StackItem>
        </Stack>
      )}
    </div>
  );
};
