import { IDAColumn } from 'types/IDADetailsList';

export const courseStatusesFields: IDAColumn[] = [
  {
    key: 'level_course',
    fieldName: 'level_course',
    name: 'Level 1 / Level 2 / Course',
    minWidth: 300,
    maxWidth: 300,
  },
  {
    key: 'priority',
    fieldName: 'priority',
    name: 'Priority',
    minWidth: 80,
    maxWidth: 80,
  },
  {
    key: 'booked_date',
    fieldName: 'booked_date',
    name: 'Booked Date',
    minWidth: 150,
    maxWidth: 200,
  },
  {
    key: 'completed_date',
    fieldName: 'completed_date',
    name: 'Completed Date',
    minWidth: 150,
    maxWidth: 200,
  },
  {
    key: 'status',
    fieldName: 'status',
    name: 'Status',
    minWidth: 150,
    maxWidth: 200,
  },
];
