import { IDropdownOption } from "@fluentui/react";

export const jobRagStatus: { [key: number]: string } = {
  1: 'green',
  2: 'amber',
  3: 'red',
};

export const jobRagStatusTypesDropdownOptions : IDropdownOption[] = 
	Object
		.entries(jobRagStatus)
		.map(([key, value]) => ({ key: key, text: value }));