/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  CommandButton,
  MessageBar,
  MessageBarType,
  TextField,
  ComboBox,
  Dropdown,
  DatePicker,
  Stack,
  IDropdownOption,
} from '@fluentui/react';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { Paper } from '../Paper';
import { opportunityStageTypesDropdownOptions } from '../../enums/opportunityStageTypes';
import { opportunityForecastTypesDropdownOptions } from '../../enums/opportunityForecastTypes';
import { jobTypesDropdownOptions } from '../../enums/jobTypes';

import { businessUnitDropdownOptions } from '../../enums/businessUnit';
import { solutionsDropdownOptions } from '../../enums/solutions';
import {
  useOpportunitySingle,
  useCreateOpportunity,
  useUpdateOpportunity,
  useDeleteOpportunity,
} from '../../hooks/useOpportunities';
import { useClientsDropdownOptions } from '../../hooks/useClients';
import { useMembersDropdownOptions } from '../../hooks/useMembers';
import { useJobs } from '../../hooks/useJobs';
import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { useToast } from 'providers/ToastContextProvider';
import { ALLOWED_STAGES_FOR_JOBS_BUDGETS } from '../../constants/opportunities';
import withAccess from '../../hocs/withAccess';
import { opportunityPermissions } from '../../enums/permissions';
import { IMember } from '../../types/IMember';
import { IOpportunity, IOpportunityView } from '../../types/IOpportunity';

const fieldStackItemProps = {
  grow: true,
  style: { flexBasis: 0, maxWidth: '33%' },
};

const getFormErrors = (form: IOpportunity | IOpportunityView): any => {
  const errors: any = {};

  if (!form.name) {
    errors.name = 'Opportunity Name is required.';
  }
  if (typeof form.resources === 'undefined' || form.resources === null) {
    errors.resources = 'Resources is required.';
  } else if (isNaN(parseFloat(form.resources + ''))) {
    errors.resources = 'Resources is not a valid number.';
  }
  if (typeof form.duration === 'undefined' || form.duration === null) {
    errors.duration = 'Duration is required.';
  } else if (isNaN(parseFloat(form.duration + ''))) {
    errors.duration = 'Duration is not a valid number.';
  }
  if (typeof form.value === 'undefined' || form.value === null) {
    errors.value = 'Value is required.';
  } else if (isNaN(parseFloat(form.value + ''))) {
    errors.value = 'Value is not a valid number.';
  }
  if (typeof form.probability === 'undefined' || form.probability === null) {
    errors.probability = 'Probability is required.';
  } else if (isNaN(parseFloat(form.probability + ''))) {
    errors.probability = 'Probability is not a valid number.';
  } else if (
    !(
      parseFloat(form.probability + '') >= 0 &&
      parseFloat(form.probability + '') <= 100
    )
  ) {
    errors.probability = 'Probability is not a valid percentage (0-100)';
  }
  if (!form.close_date) {
    errors.close_date = 'Close Date is required.';
  }
  if (!form.client_id) {
    errors.client_id = 'Client is required.';
  }
  if (!form.stage_id) {
    errors.stage_id = 'Stage is required.';
  }
  if (!form.forecast_id) {
    errors.forecast_id = 'Forecast is required.';
  }
  if (!form.account_manager_id) {
    errors.account_manager_id = 'Account Manager is required.';
  }
  if (!form.business_unit) {
    errors.business_unit = 'Business Unit is required.';
  }
  // if (!form.job_type) {
  //   errors.job_type = 'Job Type is required.';
  // }

  return errors;
};

export const OpportunityFormPage: React.FC = () => {
  const history = useHistory();
  const { opportunityId } = useParams<any>();
  const { opportunity } = useOpportunitySingle(opportunityId);
  const { setToastSuccessMessage } = useToast();

  const [data, setData] = useState(opportunity);
  const clientOptions = useClientsDropdownOptions();
  const memberOptions = useMembersDropdownOptions((member: IMember) =>
    (member.access_rules ?? []).some((ac) =>
      [opportunityPermissions.editAll, opportunityPermissions.editMe].includes(
        ac.role
      )
    )
  );
  const { jobs, isLoading: jobsLoading } = useJobs();
  const { setBreadcrumbs } = useBreadcrumbs();
  const [errors, setErrors] = useState<any>({});
  const [generalError, setGeneralError] = useState('');
  const [selectedJob, setSelectedJob] = useState<number | undefined>();
  const [jobsDropdownOptions, setJobsDropdownOptions] = useState<
    IDropdownOption[]
  >([]);

  const { create, isLoading: isCreating } = useCreateOpportunity({
    onSuccess: () => {
      history.push(`/opportunities`);
      setToastSuccessMessage('Opportunity has been created.');
    },
    onError: () =>
      setGeneralError(
        'An error occurred while trying to create a new opportunity.'
      ),
  });
  const { update, isLoading: isUpdating } = useUpdateOpportunity({
    onSuccess: () => {
      history.push(`/opportunities`);
      setToastSuccessMessage('Opportunity has been updated.');
    },
    onError: () =>
      setGeneralError(
        'An error occurred while trying to update the opportunity.'
      ),
  });
  const { delete: deleteOpportunity, isLoading: isDeleting } =
    useDeleteOpportunity({
      onSuccess: () => {
        history.push(`/opportunities`);
      },
      onError: () =>
        setGeneralError(
          'An error occurred while trying to delete the opportunity.'
        ),
    });

  const isSubmitting = useMemo(
    () => isCreating || isUpdating || isDeleting,
    [isCreating, isUpdating, isDeleting]
  );

  useEffect(() => {
    setBreadcrumbs([
      {
        key: 'opportunities',
        text: 'Opportunities',
        onClick: () => history.push('/opportunities'),
      },
      ...(opportunityId
        ? [{ key: 'opportunity', text: data?.name ?? 'Unknown' }]
        : [{ key: 'new', text: 'New' }]),
    ]);
  }, [history, data, opportunityId]);

  useEffect(() => {
    const result = (jobs ?? [])
      .filter((j) => j.client_id === data?.client_id)
      .map((j) => ({ key: j.id, text: j.name }));
    setJobsDropdownOptions([{ key: 0, text: 'New job' }, ...result]);
  }, [jobs, data?.client_id]);

  useEffect(() => {
    if (data) {
      setSelectedJob(selectedJob ?? data.job_id);
    }
  }, [data]);

  const onSubmit = async () => {
    const form = data ?? {};
    const errors = getFormErrors(form);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    form.key_person = form.key_person ?? [];
    form.solution = form.solution ?? [];
    const payload = {
      opportunity: { ...form, job_id: selectedJob },
      shouldCreateJob:
        !selectedJob && ALLOWED_STAGES_FOR_JOBS_BUDGETS.includes(form.stage_id),
    };
    if (!form.id) {
      create(payload);
    } else {
      update({ ...payload, previousOpportunity: opportunity });
    }
  };

  const onDelete = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this opportunity?')) {
      deleteOpportunity({ id: data.id.toString(), opportunity });
    }
  }, [data]);

  const onClientChange = (newValue: { key: number; text: string }) => {
    if (!newValue) return;
    setData((state) => ({ ...state, client_id: newValue.key }));
    setSelectedJob(undefined);
  };

  return (
    <Page>
      <CommandBar>
        {data?.id && (
          <>
            <CommandButton
              iconProps={{ iconName: 'Undo' }}
              text="Discard Changes"
              onClick={() => history.push(`/opportunities`)}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Delete' }}
              text="Delete Opportunity"
              onClick={onDelete}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Save' }}
              text="Update Opportunity"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
        {!data?.id && (
          <>
            <CommandButton
              iconProps={{ iconName: 'Cancel' }}
              text="Cancel"
              onClick={() => history.push(`/opportunities`)}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Save' }}
              text="Create Opportunity"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
      </CommandBar>
      <Container>
        <header>
          <h2>{data?.id ? 'Edit Opportunity' : 'Create New Opportunity'}</h2>
        </header>
        <Paper>
          <div className="da-form">
            {generalError && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {generalError}
              </MessageBar>
            )}
            <TextField
              autoFocus
              label="Opportunity Name"
              value={data?.name}
              onChange={(_: any, name: any) =>
                setData((state) => ({ ...state, name }))
              }
              errorMessage={errors.name}
              disabled={isSubmitting}
            />
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <ComboBox
                  label="Client"
                  allowFreeform={true}
                  autoComplete={'on'}
                  options={clientOptions}
                  selectedKey={data?.client_id}
                  onChange={(_: any, newValue: any) => onClientChange(newValue)}
                  errorMessage={errors.client_id}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow>
                <ComboBox
                  label="Account Manager"
                  options={memberOptions}
                  allowFreeform
                  autoComplete="on"
                  selectedKey={data?.account_manager_id}
                  onChange={(_: any, value: any) =>
                    setData((state) => ({
                      ...state,
                      account_manager_id: value.key,
                    }))
                  }
                  errorMessage={errors.account_manager_id}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow>
                <ComboBox
                  label="Key Person"
                  options={memberOptions}
                  multiSelect
                  allowFreeform
                  autoComplete="on"
                  selectedKey={data?.key_person}
                  onChange={(_: any, item: any) => {
                    if (!item?.key) return;
                    const items: string[] = [];
                    if (data?.key_person) {
                      items.push(...data.key_person);
                    }

                    const index = items.indexOf(item.key);
                    if (index !== -1) {
                      items.splice(index, 1);
                    }

                    if (item.selected) {
                      items.push(item.key);
                    }

                    setData((state) => ({ ...state, key_person: items }));
                  }}
                  errorMessage={errors.key_person}
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  label="Resources"
                  value={data?.resources ? data.resources + '' : ''}
                  onChange={(_: any, resources: any) =>
                    setData((state) => ({ ...state, resources }))
                  }
                  errorMessage={errors.resources}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow>
                <TextField
                  label="Duration (Weeks)"
                  value={data?.duration ? data.duration + '' : ''}
                  onChange={(_: any, duration: any) =>
                    setData((state) => ({ ...state, duration }))
                  }
                  errorMessage={errors.duration}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow>
                <TextField
                  label="Value"
                  value={data?.value ? data.value + '' : ''}
                  onChange={(_: any, value: any) =>
                    setData((state) => ({ ...state, value }))
                  }
                  errorMessage={errors.value}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow>
                <DatePicker
                  label="Close Date"
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  onSelectDate={(date) =>
                    setData((state) => ({
                      ...state,
                      close_date: moment(date).format('YYYY-MM-DD'),
                    }))
                  }
                  isRequired={true}
                  value={
                    data?.close_date
                      ? moment(data.close_date, 'YYYY-MM-DD').toDate()
                      : undefined
                  }
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item {...fieldStackItemProps}>
                <Dropdown
                  label="Business Unit"
                  options={businessUnitDropdownOptions}
                  selectedKey={data?.business_unit?.toString()}
                  onChange={(_: any, item: any) => {
                    setData((state) => ({ ...state, business_unit: item.key }));
                  }}
                  errorMessage={errors.business_unit}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item {...fieldStackItemProps}>
                <Dropdown
                  label="Solution(s)"
                  options={solutionsDropdownOptions}
                  multiSelect
                  selectedKeys={data?.solution?.map((s) => s.toString())}
                  onChange={(_: any, item: any) => {
                    let items: string[] = [];
                    if (data?.solution) {
                      items.push(...data.solution.map((s) => s.toString()));
                    }
                    const index = items.indexOf(item.key);
                    if (index !== -1) {
                      items.splice(index, 1);
                    }
                    if (item.selected) {
                      items.push(item.key);
                    }
                    setData((state) => ({ ...state, solution: items }));
                  }}
                  errorMessage={errors.solution_name}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item {...fieldStackItemProps}>
                <Dropdown
                  label="Job Type"
                  options={jobTypesDropdownOptions}
                  selectedKey={
                    typeof data?.job_type !== 'undefined'
                      ? data.job_type + ''
                      : ''
                  }
                  onChange={(_: any, newValue: any) =>
                    setData((state) => ({ ...state, job_type: newValue.key }))
                  }
                  errorMessage={errors.job_type}
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item {...fieldStackItemProps}>
                <Dropdown
                  label="Stage"
                  options={opportunityStageTypesDropdownOptions}
                  selectedKey={
                    typeof data?.stage_id !== 'undefined'
                      ? data.stage_id + ''
                      : ''
                  }
                  onChange={(_: any, newValue: any) =>
                    setData((state) => ({
                      ...state,
                      stage_id: parseInt(newValue.key, 10),
                    }))
                  }
                  errorMessage={errors.stage_id}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item {...fieldStackItemProps}>
                <Dropdown
                  label="Forecast"
                  options={opportunityForecastTypesDropdownOptions}
                  selectedKey={
                    typeof data?.forecast_id !== 'undefined'
                      ? data.forecast_id + ''
                      : ''
                  }
                  onChange={(_: any, newValue: any) =>
                    setData((state) => ({
                      ...state,
                      forecast_id: newValue.key,
                    }))
                  }
                  errorMessage={errors.forecast_id}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item {...fieldStackItemProps}>
                <TextField
                  label="Probability"
                  value={data?.probability ? data.probability + '' : ''}
                  onChange={(_: any, probability: any) =>
                    setData((state) => ({ ...state, probability }))
                  }
                  errorMessage={errors.probability}
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
            {ALLOWED_STAGES_FOR_JOBS_BUDGETS.includes(data?.stage_id) && (
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                  <Dropdown
                    label="Jobs"
                    options={jobsDropdownOptions}
                    onChange={(_: any, newValue: any) => {
                      if (!newValue) return;
                      setSelectedJob(newValue.key);
                    }}
                    selectedKey={selectedJob}
                    disabled={isSubmitting || jobsLoading}
                  />
                </Stack.Item>
              </Stack>
            )}
            <TextField
              label="Comments"
              value={data?.comments}
              onChange={(_: any, comments: any) =>
                setData((state) => ({ ...state, comments }))
              }
              errorMessage={errors.comments}
              disabled={isSubmitting}
              multiline
              autoAdjustHeight
            />
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

export default withAccess(
  {
    permisionAll: opportunityPermissions.editAll,
    permissionMe: opportunityPermissions.editMe,
  },
  OpportunityFormPage
);
